
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "view-sms",
  props: {
    smsList: Array,
    customerDetails: Object,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      //
    });

    return {};
  },
});
