
import { computed, defineComponent, ref } from "vue";
import QuotesService from "@/core/services/car/QuotesService";
import { hideModal } from "@/core/helpers/dom";
import { notif } from "@/store/stateless/store";
import store from "@/store";

// interface KTCreateCustomer extends Customer {}

interface RequestQuoteBodyParam {
  lead_id: number;
  notes: string;
  status: number;
  requested_to?: number | null;
}

export default defineComponent({
  name: "add-request-quote-modal",
  components: {},
  props: {
    leadId: Number,
    selectedLead: Object

  },
  setup(props) {
    let qrParameters = {} as RequestQuoteBodyParam;
    const addRequestQuoteModalRef = ref<null | HTMLElement>(null);

    let notes = ref<string>("");
    let isEmpty =  ref<boolean>(false);
    let isEmptyUnderwriter =  ref<boolean>(false);

    const addQuoteRequest = (lead_id) => {
      const currentUser = JSON.parse(window.localStorage.getItem('user') || '');
      qrParameters.notes = notes.value;
      qrParameters.lead_id = lead_id;
      qrParameters.status = 1;
      qrParameters.requested_to = requested_to.value;
      // console.log(qrParameters);
      if (qrParameters.notes !== "") {
        if(currentUser.role_id === 1 || currentUser.role_id === 14) {
          if(qrParameters.requested_to === 0 || qrParameters.requested_to === null) {
            isEmptyUnderwriter.value = true;
            return false;
          }
        }
        QuotesService.sendQuoteRequest(qrParameters);
        hideModal(addRequestQuoteModalRef.value);
        resetFormValue();
        notif.simple("Request Notes", "success", "You request has been sent successfully!");
      } else {
        isEmpty.value = true;
        return false;
      }
    };

    const resetFormValue = () => {
      notes.value = "";
    };

    const underwriters = computed(() => {
      const data = store.getters.getActiveUnderwriters
      const currentUser = JSON.parse(window.localStorage.getItem('user') || '')
      if(currentUser.role_id === 1 || currentUser.role_id === 14) {
        data.unshift({
          label: 'Select Underwriter',
          value: 0
        })
      }
      return data
    })

    const requested_to = ref(0)

    const setRequestedToValue = () => {
      const lead = props.selectedLead
      const currentUser = JSON.parse(window.localStorage.getItem('user') || '')
      requested_to.value = currentUser.role_id !== 1 || currentUser.role_id !== 14 ? currentUser.policy_agent_id : 0
    }

    const notesRef = ref()
    const focus = () => {
      notes.value = '';
      notesRef.value.focus()
    }

    return {
      notesRef,
      focus,
      setRequestedToValue,
      requested_to,
      underwriters,
      addQuoteRequest,
      notes,
      addRequestQuoteModalRef,
      resetFormValue,
      isEmpty,
      isEmptyUnderwriter,
    };
  },
});
