
import { computed, defineComponent, ref, onMounted, nextTick, getCurrentInstance } from "vue";
import { hideModal } from "@/core/helpers/dom";
// import Swal from "sweetalert2/dist/sweetalert2.js";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute } from "vue-router";
// import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import CustomerService from "@/core/services/car/CustomerService";
import MasterService from "@/core/services/car/MasterService";
import Multiselect from "@vueform/multiselect";
import { phoneNumberValidation } from '@/store/stateless/store';

// interface ICustomerPhoneParam {
//   customer_id: any;
//   phone_number: any;
//   confirm_phone_number: any;
//   phone_country_code: any;
// }

export default defineComponent({
  name: "update-phone-modal",
  components: { Multiselect },
  props: {
    showCategory: String,
    tempDocs: Array,
  },


  setup() {
    const store = useStore();
    const route = useRoute();
    // const updatePhoneNumberModalRef =  ref<null | HTMLElement>(null);
    
    let customerPhoneParam = ref({
      customer_id: route.params.customerId.toString(),
      phone_number: "",
      confirm_phone_number: "",
      phone_country_code: "",
      confirm_phone_country_code:""
    });
    
    let ShowLoading = ref(false);
    const rules = ref({
      phone: {
        show: false,
        message: "Phone Number is required",
        length_message:"Phone number should be greater than 8 digits",
      },
      confirm_phone: {
        show: false,
        message: "Confirm Phone Number is required",
        length_message:"Confirm Phone number should be greater than 4 digits",
      },
      phone_match: {
        show: false,
        message: "Phone Number do not match",
      },
      phone_country_code: {
        show: false,
        message: "Please select Country code",
      },
      confirm_phone_country_code: {
        show: false,
        message: "Please select Confirm Country code",
      },
    });

    let isFormValid = ref(true);

    let disableCustomerPhoneButton = computed(() => {
      return store.getters.getDisableCustomerPhoneButton;
    });
    const resetFormValue = () => {
      CustomerService.clearIsPhoneError();
      customerPhoneParam.value = {
        customer_id: route.params.customerId.toString(),
        phone_number: "",
        confirm_phone_number: "",
        phone_country_code: "",
        confirm_phone_country_code: "",
      };
      rules.value.phone.show = false;
      rules.value.confirm_phone.show = false;
      rules.value.phone_match.show = false;
      rules.value.confirm_phone.show = false;
      rules.value.confirm_phone_country_code.show = false;
      close();
    };

    const isErrorPhone = computed(() => {
      return store.getters.getIsPhoneError
    })

    const updateCustomerPhone = () => {
      
      isFormValid.value = true;

        if (customerPhoneParam.value.phone_number == "") {
          rules.value.phone.show = true;
          isFormValid.value = false;
        }

        if(customerPhoneParam.value.phone_number && customerPhoneParam.value.phone_number.length < phoneNumberValidation.setLimit(countryCodes.value.value,'max')) {
          rules.value.phone.message = `Phone number must not be less than ${phoneNumberValidation.setLimit(countryCodes.value.value,'max')} digits`;
          rules.value.phone.show = true;
          isFormValid.value = false;
        }
        if (customerPhoneParam.value.confirm_phone_number == "") {
          rules.value.confirm_phone.show = true;
          isFormValid.value = false;
        }
          
        if(customerPhoneParam.value.confirm_phone_number && customerPhoneParam.value.confirm_phone_number.length < phoneNumberValidation.setLimit(confirmCountryCodes.value.value,'min')) {
          rules.value.confirm_phone.message = `Confirm phone number must not be less than ${phoneNumberValidation.setLimit(confirmCountryCodes.value.value,'min')} digits`;
          rules.value.confirm_phone.show = true;
          isFormValid.value = false;
        }

        if (countryCodes.value.value == null || countryCodes.value.value == ""
        ) {
          rules.value.phone_country_code.show = true;
          isFormValid.value = false;
        }

        if (confirmCountryCodes.value.value == null || confirmCountryCodes.value.value == ""
        ) {
          rules.value.confirm_phone_country_code.show = true;
          isFormValid.value = false;
        }
        // 
        if (
          customerPhoneParam.value.phone_number != "" &&
          (customerPhoneParam.value.phone_number !=
            customerPhoneParam.value.confirm_phone_number ||
            confirmCountryCodes.value.value != countryCodes.value.value)
        ) {
          rules.value.phone_match.show = true;
          isFormValid.value = false;
        }

      if (isFormValid.value) {
        ShowLoading.value = true;
        Promise.all([
          CustomerService.updateCustomerPhone({
            customer_id: route.params.customerId.toString(),
            phone_number: customerPhoneParam.value.phone_number,
            phone_country_code: confirmCountryCodes.value.value,
          }),
        ]).then((data) => {
            ShowLoading.value = false;
            resetFormValue();
            close();
            // hideModal(updatePhoneNumberModalRef.value);
          // 
          // if (!data[0].data.success && data[0].data.success != undefined) {
          //   let message = store.getters.getCustomerErrors;
          //   ElMessage.error(message[0]);
          // } else {
          //   ElMessage({
          //     message: data[0].message,
          //     type: "success",
          //   });
            
          // }
         

          CustomerService.getCustomerDetails(store, route.params.customerId);
        }).catch((error) => {
           ShowLoading.value = false;
        });
      }
    };

    const inst = getCurrentInstance()

    onMounted(() => {
      MasterService.getCountryCodes();

      nextTick(() => {
        // otp_1.value.focus();
      });

      setTimeout(() => {
        if(updatePhoneModal.value) {
          const phoneNumberRef:any = inst?.refs?.phoneNumberRef
          phoneNumberRef.focus()
        }
      }, 1000);
    });
    let countryCodeList = computed(() => {
      return store.getters.getCountryCode;
    });
    let countryCodes = ref({
      value: "+961",
      options: countryCodeList,
      searchable: true,
      createTag: false,
    });
    let confirmCountryCodes = ref({
      value: "+961",
      options: countryCodeList,
      searchable: true,
      createTag: false,
    });

    const active = ref(1);

    const next = () => {
      if (active.value == 1) {
        isFormValid.value = true;

        if (customerPhoneParam.value.phone_number == "") {
          rules.value.phone.show = true;
          isFormValid.value = false;
        }

        if(customerPhoneParam.value.phone_number && customerPhoneParam.value.phone_number.length < phoneNumberValidation.setLimit(countryCodes.value.value,'min')) {
          rules.value.phone.message = `Phone number must not be less than ${phoneNumberValidation.setLimit(countryCodes.value.value,'min')} digits`;
          rules.value.phone.show = true;
          isFormValid.value = false;
        }
        if (customerPhoneParam.value.confirm_phone_number == "") {
          rules.value.confirm_phone.show = true;
          isFormValid.value = false;
        }
          
        if(customerPhoneParam.value.confirm_phone_number && customerPhoneParam.value.confirm_phone_number.length < phoneNumberValidation.setLimit(confirmCountryCodes.value.value,'min')) {
          rules.value.confirm_phone.message = `Confirm phone number must not be less than ${phoneNumberValidation.setLimit(confirmCountryCodes.value.value,'min')} digits`;
          rules.value.confirm_phone.show = true;
          isFormValid.value = false;
        }

        if (countryCodes.value.value == null || countryCodes.value.value == ""
        ) {
          rules.value.phone_country_code.show = true;
          isFormValid.value = false;
        }

        if (confirmCountryCodes.value.value == null || confirmCountryCodes.value.value == ""
        ) {
          rules.value.confirm_phone_country_code.show = true;
          isFormValid.value = false;
        }
        // 
        if (
          customerPhoneParam.value.phone_number != "" &&
          (customerPhoneParam.value.phone_number !=
            customerPhoneParam.value.confirm_phone_number ||
            confirmCountryCodes.value.value != countryCodes.value.value)
        ) {
          rules.value.phone_match.show = true;
          isFormValid.value = false;
        }
        if (isFormValid.value) {
          sendOTP();
        }
      }
      if (active.value == 2) {
        CustomerService.verifyOTPandUpdatePhoneNumber({
          token: store.getters.getOtpToken,
          otp:
            `${otp_1.value}` +
            `${otp_2.value}` +
            `${otp_3.value}` +
            `${otp_4.value}`,
          phone_country_code: confirmCountryCodes.value.value,
          phone_number: customerPhoneParam.value.phone_number,
        }).then((result) => {
          active.value++;
          responseData.value = result.data.response;
        });
      }
    };

    const responseData = ref({});

    const sendOTP = () => {
      CustomerService.sendOTP({
        customer_id: route.params.customerId.toString(),
        phone_number: customerPhoneParam.value.phone_number,
        phone_country_code: confirmCountryCodes.value.value,
      }).then(() => {
        active.value = 2;
      });
    };

    const otpLoading = computed(() => {
      return store.getters.getOtpLoading;
    });

    const otpToken = computed(() => {
      return store.getters.getOtpToken;
    });

    const otp_1 = ref();
    const otp_2 = ref();
    const otp_3 = ref();
    const otp_4 = ref();

    const resetAndClose = () => {
      active.value = 1;
      otp_1.value = null;
      otp_2.value = null;
      otp_3.value = null;
      otp_4.value = null;
      customerPhoneParam.value = {
        customer_id: route.params.customerId.toString(),
        phone_number: "",
        confirm_phone_number: "",
        phone_country_code: "",
        confirm_phone_country_code:""
      };
      rules.value.phone.show = false;
      rules.value.confirm_phone.show = false;
      rules.value.phone_match.show = false;
      // if (responseData.value.status == 1) {
      //   CustomerService.getCustomerDetails(
      //     store,
      //     customerPhoneParam.value.customer_id
      //   );
      // }
      close();
      // hideModal(updatePhoneNumberModalRef.value);
    };

    const otpError = computed(() => {
      return store.getters.getOTPError;
    })

    const updatePhoneModal = computed(() => {
        return store.getters.getUpdatePhoneModal;
    });

    const close = () => {
        
      CustomerService.setUpdatePhoneNumberModal(false);
    
    }

    const toFirst = () => {
        setTimeout(() => {
          const phoneNumberRef:any = inst?.refs?.phoneNumberRef
        phoneNumberRef.focus()
        }, 500);
    }

    return {
      isErrorPhone,
      toFirst,
      close,
      updatePhoneModal,
      sendOTP,
      otpError,
      responseData,
      resetAndClose,
      otp_1,
      otp_2,
      otp_3,
      otp_4,
      otpLoading,
      otpToken,
      next,
      active,
      updateCustomerPhone,
      rules,
      resetFormValue,
      disableCustomerPhoneButton,
      // updatePhoneNumberModalRef,
      ShowLoading,
      countryCodes,
      confirmCountryCodes,
      customerPhoneParam,
      phoneNumberValidation
    };
  },
});
