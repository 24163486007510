
import { defineComponent, computed } from "vue";
import PolicyService from "@/core/services/car/PolicyService";

import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "lead-policy-details-action",
  components: {},
  props: {
    policy: Object,
    action: String,
    showcancelAction: Boolean,
    workFlow: String,
    isShowRefund: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCancellation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useStore();

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const viewPolicyDocument = (policy) => {
        window.open(policy.file_path,'_blank');
    }
    /**
     * return data
     *
     */
    return {
      viewPolicyDocument,
      // uploadFile,
      // formData
      userPermissions,
      router,
    };
  },
});
