
import { computed, defineComponent, ref, onMounted, onBeforeMount, getCurrentInstance } from "vue";
// import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import PolicyService from "@/core/services/car/PolicyService";
import LeadPolicyDetailsAction from "@/components/dropdown/car/LeadPolicyDetailsAction.vue";
import MasterService from "@/core/services/car/MasterService";
import { useStore } from "vuex";
import moment from "moment";
import { Mutations } from "@/store/enums/StoreEnums";
import { statusModal } from "@/store/stateless/store";

export default defineComponent({
  name: "list-policy-explore",
  components: { LeadPolicyDetailsAction },
  props: {
    leadPolicyList: Array,
    leadDetails: Array
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"]
    };
  },
  setup(prop) {
    const store = useStore();
    let isActive = false;
    let cancellationReasonList = ref<any>();
    Promise.all([MasterService.getCancellationReason()]).then((data) => {
      cancellationReasonList.value = computed(() => {
        return store.getters.getCancellationReasons;
      });
    });
    let isLoaded = ref<boolean>(false);

    // let customerStatusLogsBodyParam = {} as CustomerStatusLogsBodyParam;
    onMounted(() => {
      //
    });
    // const getData = computed(() => {
    //   return props.customerStatusLogList ? true : false;
    // });

    const dropdowns = computed(() => {
      // return store.getters.getLeadPolicyLists
      const data = store.getters.getLeadPolicyLists;
      return data.map((val) => ({
        item: `${
          val.car_insurance_policy ? val.car_insurance_policy.policy_name : ""
        } | ${val.car_lead_quote.quote_ref_no} | ${moment(
          val.created_at,
          "DD/MM/YYYY HH:mm"
        ).format("YYYY")}`,
        value: val.id
      }));
    });

    const policyLogsValues = computed(() => {
      const data = store.getters.getPolicyLeadLogs;
      return data.map((x : any) => ({
        description: JSON.parse(x.description),
        ...x
      }))
    });

    let selectedPolicy = ref<number>(0);

    const policyActive = computed(() => {
      return store.getters.getPolicyIsActive;
    });

    const getLeadPolicies = (customer_id, lead_id) => {
      isLoaded.value = true;
      // console.log(lead_id, customer_id);
      PolicyService.resetLeadsLog()
      selectedPolicy.value = 0
      PolicyService.getLeadPolicies({
        lead_id: lead_id,
        customer_id: customer_id
      });
    };

    const getPolicyLog = () => {
      if (selectedPolicy.value > 0) {
        PolicyService.getLeadPoliciesLogs({
          policy_purchase_id: selectedPolicy.value
        });
      } else {
        store.commit(Mutations.SET_POLICY_LOGS, []);
      }
    };

    const setDate = (e) => {
      return moment(e, "DD/MM/YYYY HH:mm").zone("+0400").format("DD/MM/YYYY HH:mm");
    };

    const sendPolicyId = (id, category, lead_id) => {
      //   console.log({
      //     "id": id,
      //     "category": category
      //   })
      //   childPolicyId.value = id;
      PolicyService.resetPolicyDocumentValues([]);
      Promise.all([
        PolicyService.getPolicyDetails({
          policy_id: id
        })
      ]).then((data) => {
        // setTimeout(() => {
        // policyDetails = computed(() => {
        //   return store.getters.getpolicyDetails;
        // });
        // if (policyDetails.value.status > 4 || category == "cancel") {
        //   categories.value = "cancellation";
        //   getCancellationDocument(id);
        // } else {
        // categories.value = category;
        if (category == "cancel") {
          statusModal.showCategory = "cancellation";
          PolicyService.getPolicyCancellationDocuments({
            policy_id: id,
            categories: "cancellation"
          });
        } else {
          let title = category == "policy" ? "Policy Certificate" : "";
          statusModal.showCategory = title;
          // PolicyService.getPolicyDocuments({ policy_id: id, title });
          PolicyService.getAllDocuments({
            policy_id: id,
            lead_id
          })
          PolicyService.getPolicyDocuments({
            policy_id: id,
            lead_id
          });
          PolicyService.getTempPolicyDocuments({
            policy_id: id,
            lead_id
          });
        }
        // }
        // getTempDocument(id);
        // }, 4000);
      });
    };

    const nameCount = (a: any, b: any, c: any, d: any) => {
      return a.length + b.length + c.length + d?.length;
    };

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    // const sendPolicyId = (policy_id, category) => {
    //   let title = category == 'policy' ? 'Policy Certificate' : '';
    //   PolicyService.getPolicyDocuments({ policy_id, title })
    //   .then((result) => {
    //     console.log(result)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    // };

    const lists = computed(() => {
      let data = prop.leadDetails;
      // const quickLead = [0];
      const quickLead = [2, 10];
      if (Array.isArray(data)) {
        // return data?.filter((lead: any) => lead.lead_status_id != 2);
        return data?.map((val: any) => ({
          ...val,
          is_disable: !quickLead.includes(val.lead_status_id) ? false : true
        }));
      }
      return data;
    });

    const isShow = ref(false);
    const changeEvent = (e) => {
      PolicyService.resetLeadsLog()
      selectedPolicy.value = 0
      isShow.value = e;
    };

    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const inst = getCurrentInstance()
    const closingTime = () => {
      const firstTab:any = inst?.refs?.firstTab
      const policiesListRef:any = inst?.refs?.policiesListRef
      if(firstTab){
        firstTab[0].click()
      }
      policiesListRef.click()
      
    }

    const firstList:any = computed(() => {
      const data =  prop.leadDetails
      const quickLead = [2, 10]
      return data?.find((val:any) => !quickLead.includes(val.lead_status_id))
    })

    const firstClick = () => {
      if(firstList.value) {
        setTimeout(() => {
          const listRef:any = inst?.refs[`list_ref_${firstList.value.id}`]
            listRef[0].click()
        }, 500);
      }
    }

    return {
      // getData,
      firstClick,
      firstList,
      closingTime,
      closeRef,
      closeModal,
      isShow,
      changeEvent,
      statusModal,
      lists,
      nameCount,
      setDate,
      sendPolicyId,
      policyLogsValues,
      policyActive,
      isLoaded,
      getPolicyLog,
      selectedPolicy,
      dropdowns,
      isActive,
      getLeadPolicies,
      cancellationReasonList,
      userPermissions
    };
  }
});
