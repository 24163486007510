
import { computed, defineComponent, getCurrentInstance, onBeforeMount, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
// import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import CustomerService from "@/core/services/car/CustomerService";
import { validation } from '@/store/stateless/store';

export default defineComponent({
  name: "update-email-modal",
  components: {},
  props: {
    showCategory: String,
    tempDocs: Array,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const updateEmailModalRef = ref<null | HTMLElement>(null);
    let ShowLoading = ref<boolean>(true);
    const rules = ref({
      email: {
        show: false,
        message: "Email is required",
      },
      confirm_email: {
        show: false,
        message: "Confirm Email is required",
      },
      email_match: {
        show: false,
        message: "Email do not match",
      },
    });
    const customerEmail = ref<string>("");
    const confirmCustomerEmail = ref<string>("");
    let isFormValid = ref<boolean>(true);

    let disableCustomerEmailButton = computed(() => {
      return store.getters.getDisableCustomerEmailButton;
    });
    const resetFormValue = () => {
      customerEmail.value = "";
      confirmCustomerEmail.value = "";
      rules.value.email.show = false;
      rules.value.confirm_email.show = false;
      rules.value.email_match.show = false;
      clearIsError()
    };
    const updateCustomerEmail = () => {
      isFormValid.value = true;

      if (customerEmail.value == "") {
        rules.value.email.show = true;
        isFormValid.value = false;
      }
      else if (customerEmail.value != "" && !validation.validateEmail(customerEmail.value)) {
        rules.value.email.show = true;
        rules.value.email.message = 'Please enter valid email address';
        isFormValid.value = false;
      }
      else {
         rules.value.email.show = false;
      }


      if (confirmCustomerEmail.value == "") {
        rules.value.confirm_email.show = true;
        isFormValid.value = false;
      }
      else if (confirmCustomerEmail.value != "" && !validation.validateEmail(confirmCustomerEmail.value)) {
        rules.value.confirm_email.show = true;
        rules.value.confirm_email.message = 'Please enter valid email address';
        isFormValid.value = false;
      }
      else {
         rules.value.confirm_email.show = false;
      }


      if (
        confirmCustomerEmail.value != "" &&
        customerEmail.value != confirmCustomerEmail.value
      ) {
        rules.value.email_match.show = true;
        isFormValid.value = false;
      }
      else{
        rules.value.email_match.show = false;
      }

      if (isFormValid.value) {
        ShowLoading.value = true;
        Promise.all([
          CustomerService.updateCustomerEmail({
            customer_id: route.params.customerId.toString(),
            email: customerEmail.value,
          }),
        ])
          .then((data) => {
            resetFormValue();
            ShowLoading.value = false;
            hideModal(updateEmailModalRef.value);
            // CustomerService.getCustomerDetails(store, route.params.customerId);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const error = computed(() => {
      return store.getters.getAuthErrors;
    });

    const inst = getCurrentInstance()

    const toFirst = () => {
      setTimeout(() => {
        const emailRef:any = inst?.refs?.emailRef
        emailRef.focus()
      }, 500);
    }
    
    const isErrorEmail = computed(() => {
      return store.getters.getIsEmailError
    })

    const clearIsError = () => {
      CustomerService.clearIsError()
      rules.value.email.show = false
    }

    const closeModalRef = ref()
    const closeModal = (e) => {
      if(e.which === 27) {
        closeModalRef.value.click()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })
    
    return {
      closeModalRef,
      clearIsError,
      isErrorEmail,
      toFirst,
      error,
      updateCustomerEmail,
      rules,
      resetFormValue,
      confirmCustomerEmail,
      customerEmail,
      disableCustomerEmailButton,
      updateEmailModalRef,
      ShowLoading,
    };
  },
});
