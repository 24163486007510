
import { computed, defineComponent, ref, onMounted, onBeforeMount, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import AddNotesModal from "@/components/modals/forms/AddNotesModal.vue";
import NotesService from "@/core/services/car/NotesService";
interface NotesBodyParam {
  lead_id: number;
}
export default defineComponent({
  name: "notes-explore",
  components: {
    AddNotesModal,
  },
  props: {
    notesList: Array,
    leadDetails: Array,
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup() {
    const store = useStore();
    let isActive = false;
    let notesBodyParam = {} as NotesBodyParam;
    let activeLeadId = ref<number>(-1);
    const getLeadNotes = (lead_id) => {
      activeLeadId.value = lead_id;
      notesBodyParam.lead_id = lead_id;
      NotesService.getLeadNotesList(notesBodyParam);
    };
    const setActiveLead = (lead_id) => {
      const inputs:Array<any> = Array.from(document.querySelectorAll('#notes-modal'));
      setTimeout(() => inputs[0].focus(), 500);
      activeLeadId.value = lead_id;
    };

    const notes = ref();
    onMounted(() => {
      activeLeadId.value = -1;
    });
    const showNotesDom = computed(() => {
      return store.getters.getNotesDomStatus;
    });
    //    const deleteTask = () => {
    //      TaskService.deleteTask(deleteTaskBodyParam);
    //    }
    // const showAction = () => {
    //   //
    // }

    const nameCount = (a: any, b: any, c: any, d: any) => {
      return a.length + b.length + c.length + d?.length;
    };
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const inst = getCurrentInstance()

    const toFirst = () => {
      setTimeout(() => {
        const addNotesModalRef:any = inst?.refs?.addNotesModalRef
        addNotesModalRef.focus()
      }, 1000);
    }

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if([7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })
    

    return {
      notOmMAs,
      toFirst,
      closeModal,
      closeRef,
      notes,
      nameCount,
      getLeadNotes,
      isActive,
      activeLeadId,
      setActiveLead,
      showNotesDom,
    };
  },
});
