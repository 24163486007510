
import { computed, defineComponent, ref, onMounted, onBeforeMount, getCurrentInstance, watchEffect } from "vue";
import PolicyService from "@/core/services/car/PolicyService";
import { useStore } from "vuex";
import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import DropzoneFile from "@/views/car/policy/DropZone.vue";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
import AddQuoteRequestModal from "@/components/modals/forms/AddQuoteRequestModal.vue";
import router from '@/router';
import { Mutations } from "@/store/enums/StoreEnums";

// import ViewPreviousPolicyDocuments from "@/components/partials/policy/ViewPreviousPolicyDocuments.vue";
import LeadDocumentsVue from "./Lead-Documents.vue";
import UserService from "@/core/services/UserService";

interface LeadDocumentsBodyParm {
  lead_id: number;
}
export default defineComponent({
  name: "lead-documents-explore",
  components: {
    PolicyDocuments,
    DropzoneFile,
    ViewTempDocuments,
    AddQuoteRequestModal,
    // ViewPreviousPolicyDocuments,
  },
  props: {
    leadDocuments: {
      type: Object,
      required: true
    },
    leadDetails: { type: Array, required: true },
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup(props) {
   
  
    const store = useStore();
    let isActive = false;
    let leadDocumentsBodyParm = {} as LeadDocumentsBodyParm;
    let leadId = ref<any>(null);
    let selectedLeadId = ref(null);
    let tempDocs = ref<any>("");
    let previousYearPolicyDocuments = ref<any>("");
    let activeLeadId = ref<number>(-1);
    // let previousYearPolicyStatus = ref<any>({ policy_id: null, status: false });
    let isViewDocumentTab = ref<boolean>(true);
    let isUploadDocumentTab = ref<boolean>(false);

    const notes = ref()
    const setActiveLead = (lead_id) => {
      const inputs:Array<any> = Array.from(document.querySelectorAll('#notes-modal'));
      // setTimeout(() => inputs[0].focus(), 500);
      setTimeout(() => {
        notes.value.focus()
        notes.value.setRequestedToValue()
      }, 500);
      activeLeadId.value = lead_id;
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const errorMessage = computed(() => {
      return store.getters.getDocumentError;
    });
    const getLeadDocuments = (lead_id) => {
      leadDocumentsBodyParm.lead_id = lead_id;
      PolicyService.getLeadDocuments(leadDocumentsBodyParm);
      // PolicyService.getLeadActivePolicyDocuments(leadDocumentsBodyParm);
    };
    const setSelectedLeadId = (leadId, tab) => {

      selectedLeadId.value = leadId;
      if(tab == 'view')
      {
        isViewDocumentTab.value = true;
        isUploadDocumentTab.value = false;

        getLeadPolicyDocuments(leadId);
      }
      if(tab == 'upload')
      {
        isViewDocumentTab.value = false;
        isUploadDocumentTab.value = true;
        getTempDocument(leadId);
      }
      
    };
    const updateLeadId = (event) => {
   
      isViewDocumentTab.value = true;
      isUploadDocumentTab.value = false;
      isClicked.value = false
      leadId.value = event;
   
      if (event != "-1") {
        store.commit(Mutations.SET_LEAD_DOCUMENTS, []);
      
        getLeadPolicyDocuments(leadId.value);
      }
    };

    const getLeadPolicyDocuments = (leadId) => {
      Promise.all([
          PolicyService.getLeadDocuments({
            lead_id: leadId
          }),
        ]).then((data) => {
            console.log(data)
        });
        //PolicyService.getLeadActivePolicyDocuments({ lead_id: leadId });  
          PolicyService.getPreviousPolicyDetails({
            lead_id: leadId,
        });
    }
    const previousYearPolicyStatus = computed(() => {
      return  store.getters.getPreviousYearPolicyDetails;
    })
    const getTempDocument = (leadId) => {
      Promise.all([
        PolicyService.getTempPolicyDocuments({
          lead_id: leadId,
          categories: "standard",
        }),
      ]).then((data) => {
        tempDocs.value = computed(() => {
          return store.getters.getTempPolicyDocs;
        });
      });
    };
    const getPreviousPolicyDocuments = (leadId) => {
      isClicked.value = true;
      
      Promise.all([
        PolicyService.getPreviousPolicyDocuments({
          policy_id: previousYearPolicyStatus.value.policy_id,
          type: "verification",
        })]).then((data) => {
        setTimeout(() => {
          getTempDocument(leadId)
        },500);
        
        tempDocs.value = computed(() => {
          return store.getters.getPreviousPolicyDocumentList;
        });
        setTimeout(() => isClicked.value = false, 2000);
      });
    };
    onMounted(async() => {
      await UserService.getActiveUnderwriters();
    });
    // 
    // getTempDocument(props.leadDetails.id);
    // Promise.all([
    //   PolicyService.getPreviousPolicyDetails({
    //     lead_id: event,
    //   }),
    // ]).then((data) => {
    //   
    //   previousYearPolicyStatus.value =
    //     store.getters.getPreviousYearPolicyDetails;
    // });
    const showPolicyDom = computed(() => {
      return store.getters.getPolicyDocumentDomStatus;
    });

    const policy_list = computed(() => {
      return store.state.PolicyModule.leadDocuments;
    });

    const leadList = computed(() => {
      return store.getters.getLeadDetails;
    });

    const show = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );
        if (lead) {
          return lead.lead_status_id;
        }
      }
      return 0;
    });
    const activePolicyId = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );
        // if (lead && lead.policy_list && (lead.active_policy.status == 2)) { //commented by Ashwin B. lead obj was not having policy list in it
        if (lead && lead.active_policy && lead.active_policy.status == 2) {
          return lead.active_policy.id;
        }
      }
      return null;
    })

    const isPolicyAssigned = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );
        // if (lead && lead.policy_list && (lead.active_policy.status == 2)) { //commented by Ashwin B. lead obj was not having policy list in it
        if (lead && lead.active_policy && lead.active_policy.status == 2) {
          return true;
        }
      }
      return false;
    })

    const isShowMakeInvoice = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );
        if (lead && lead.is_show_make_invoice) {
          return lead.is_show_make_invoice;
        }
      }
      return false;
    })

    const in_array = computed(() => {
      const data = [9, 10, 2, 11, 4, 12, 14];
      return data.includes(show.value);
    });

    const showDealUnfinish = computed(() => {
      if(show.value == 4 && isPolicyAssigned.value) {
        return true;
      }
      return false;
    })

    const lists = computed(() => {
      let data = props.leadDetails;
      const quickLead = [2, 10]
      if (Array.isArray(data)) {
        return data?.filter((lead: any) => !quickLead.includes(lead.lead_status_id));
      }
      return data;
    });

  const leadIdTab = computed(() => {
    return store.getters.uploadLeadIdTab
  });

    const selectedLead = computed(() => {
      
      // console.log(leadIdTab);
      if(leadIdTab.value) {
        return leadIdTab.value;
      }
       return props.leadDocuments.lead_id;
    })

    watchEffect(() => {
      if(leadIdTab.value > 0)
      {
        isViewDocumentTab.value = false;
        isUploadDocumentTab.value = true;

        getTempDocument(leadIdTab.value);
      }
    })

    const clearData = () => {
      isViewDocumentTab.value = true;
      isUploadDocumentTab.value = false;
      store.commit(Mutations.SET_LEAD_DOCUMENTS, []);
      store.commit(Mutations.SET_TEMP_DOCUMENTS, []);
      store.commit(Mutations.SET_UPLOAD_TAB_LEAD_ID, 0);
    };

    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const inst = getCurrentInstance()

    const toFirst = () => {
      setTimeout(() => {
        const selectLeadRef:any = inst?.refs?.selectLeadRef
        selectLeadRef.focus()
      }, 1000);
    }

    let getLeadDocumentList = computed(() => {
      return store.getters.getLeadDocumentList;
    });

    const showPrevYear = computed(() => {
      if(leadList.value.length > 0 && Object.keys(getLeadDocumentList.value).length > 0) {
        const openLead = leadList.value.find(x => getLeadDocumentList.value.lead_id === x.id);
        return openLead.lead_status_id === 5 ? true : false;
      }
      return false
    })

    const isClicked = ref(false);

    const isFilter = ref(false)

    const showUploadDocuments = computed(() => {
      if (leadList.value.length > 0) {
        const lead = leadList.value.find(
          (e) => e.id == policy_list.value.lead_id
        );

        const latest_policy_purchase_status = lead && lead.latest_policy_purchase ?lead.latest_policy_purchase.status : 0
        if (lead && ![2, 9, 10].includes(lead.lead_status_id) && (![10,7,8,9,4,11].includes(latest_policy_purchase_status) || lead.is_show_make_invoice)) {
          return true;
        }
      }
      return false;
    })

    const selectedLeadDetail = computed(() => {
      let data = props.leadDetails;
      if(selectedLead.value) {
        return data.find((x:any) => x.id === selectedLead.value)
      }
      return {}
    })

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    return {
      notOmMAs,
      notes,
      selectedLeadDetail,
      showUploadDocuments,
      showPrevYear,
      isFilter,
      isClicked,
      getLeadDocumentList,
      toFirst,
      isViewDocumentTab,
      isUploadDocumentTab,
      leadIdTab,
      clearData,
      closeRef,
      closeModal,
      selectedLead,
      isShowMakeInvoice,
      isPolicyAssigned,
      showDealUnfinish,
      lists,
      in_array,
      show,
      leadList,
      policy_list,
      errorMessage,
      isActive,
      getLeadDocuments,
      updateLeadId,
      showPolicyDom,
      leadId,
      tempDocs,
      previousYearPolicyStatus,
      getPreviousPolicyDocuments,
      previousYearPolicyDocuments,
      setSelectedLeadId,
      selectedLeadId,
      activePolicyId,
      setActiveLead,
      activeLeadId,
      user
    };
  },
});
