
import { computed, defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ViewSms from "@/components/partials/sms/View-Sms.vue";
import SmsService from "@/core/services/car/SmsService";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "sms-explore",
  components: { ViewSms },
  props: {
    smsList: Array,
    customerDetails: Array,
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup() {
    const store = useStore();

    const route = useRoute();
    let isActive = false;
    // let smsList: any;

    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      closeRef,
      closeModal,
      isActive,
    };
  },
});
