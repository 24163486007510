
import { computed, defineComponent, onMounted, ref, getCurrentInstance, onBeforeUnmount, onBeforeMount } from "vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import LDDExplore from "@/components/driver-details/List.vue";
import PolicyDocumentModal from "@/components/modals/forms/PolicyDocumentModal.vue";
// import LTExplore from "@/components/tasks/List-Task.vue";
import PLExplore from "@/components/policy/List-Policy.vue";
import LLTExplore from "@/components/tasks/Lead-List-Task.vue";
import QuotesAction from "@/components/dropdown/car/QuotesAction.vue";
import CustomerService from "@/core/services/car/CustomerService";
import QuotesService from "@/core/services/car/QuotesService";
import TaskService from "@/core/services/car/TaskService";
import PolicyService from "@/core/services/car/PolicyService";
import LeadService from "@/core/services/car/LeadService";
import MasterService from "@/core/services/car/MasterService";
import { DrawerComponent } from "@/assets/ts/components";
// import Multiselect from "@vueform/multiselect";
import moment from "moment";
import { hideModal } from "@/core/helpers/dom";
import { leadStatusMixin } from "@/core/plugins/vcMixins";
import { statusModal, ct,otherGenders } from "@/store/stateless/store";
import { Mutations } from "@/store/enums/StoreEnums";
import TaskDetails from "@/components/leads/TaskDetails.vue";
import router from "@/router";

interface QuotesBodyParam {
  customer_id: number;
  lead_id: number;
}

interface LeadStatusBodyParm {
  car_lead_id: number;
  customer_id: number;
}
interface mergeQuickLeadBodyParam {
  car_lead_id: string;
  ql_lead_id: string;
  customer_id: string;
}
interface PolicyBodyParam {
  policy_id: any;
  lead_id: any;
}
interface CheckLeadMergeParam {
  customer_id: number;
  car_lead_id: number;
}
interface MergeLeadBodyParam {
  new_car_lead_id: number;
  car_lead_id: number;
}
export default defineComponent({
  name: "Lead-List",
  components: {
    LDDExplore,
    // LTExplore,
    PLExplore,
    PolicyDocumentModal,
    LLTExplore,
    QuotesAction,
    TaskDetails
  },
  props: {
    list: { type: Array },
    customer: Array
  },

  computed: {
    // getQuickCarLeads: (data) => {
    //   if (data.list !== undefined && data.list.length > 0) {
    //     return data.list.filter(
    //       (lead) => lead.make !== "QL" && lead.model !== "QL"
    //     );
    //   }
    // },
    getMergeCarLeads: (data) => {
      if (data.list !== undefined && data.list.length > 0) {
        return data.list.filter(
          (lead) =>
            lead.make !== "QL" &&
            lead.model !== "QL" &&
            (lead.lead_status_id == 4 || lead.lead_status_id == 6)
        );
      }
    }
    // getLeadList: (data) => {
    //   return data.list[0];
    // },
  },

  setup(props, context) {
    const LeadList = ref(props.list);
    const getLeadList = computed(() => {
      return LeadList.value;
    });

    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance()

    let validateDisposition = ref<boolean>(false);
    let validateDueDate = ref<boolean>(false);
    let isTaskFormValid = ref<boolean>(true);
    let customerResponseValue = ref<any>("");
    let lostReasonValue = ref<string>("");
    let dueDateAt = ref<any>();
    let quotesBodyParam = {} as QuotesBodyParam;
    let mergeQuickLeadBodyParam = {} as mergeQuickLeadBodyParam;
    let leadStatusBodyParm = {} as LeadStatusBodyParm;
    let checkLeadMergeParam = {} as CheckLeadMergeParam;
    let policyBodyParam = {} as PolicyBodyParam;
    let mergeLeadBodyParam = ref<MergeLeadBodyParam>({
      new_car_lead_id: 0,
      car_lead_id: 0
    });
    // taskParameters.closed_lead = null;
    const transferPolicyModalRef = ref<null | HTMLElement>(null);
    const transferPolicyModalRef1 = ref<null | HTMLElement>(null);
    const taskRules = ref({
      disposition_id: {
        show: false,
        message: "Please select the Customer Response"
      },
      lost_lead_reason: {
        show: false,
        message: "Please enter Lost Lead Reason"
      },
      followup_time: {
        show: false,
        message: "Please select Follow up due date & time"
      },
      task_notes: {
        show: false,
        message: "Please enter Notes"
      },
      closed_lead: {
        show: false,
        message: "Please select closed lead option"
      }
    });

    // const policy_id = ref(null);
    // const inst = getCurrentInstance()
    onMounted(() => {
      const listRef:any = inst?.refs?.listRef
      if(listRef.length > 1 && ct.currentLead === 0) {
        setTimeout(() => {
          if(listRef[0] !== undefined) {
            listRef[0].click()
          }
        }, 500);
      } 

      if(listRef.length > 1 && ct.currentLead > 0) {
        setTimeout(() => {
          const val = LeadList.value?.findIndex((el:any) => el.id = ct.currentLead)
          const index = val ? val : 0
          listRef[index].click()
        }, 500);
      } 

      
      let firstLead = {} as any;
      firstLead =
        getLeadList.value && getLeadList.value.length > 0
          ? getLeadList.value[0]
          : {};
      //
      DrawerComponent.bootstrap();

      DrawerComponent.updateAll();
      quotesBodyParam = {
        customer_id: -1,
        lead_id: -1
      };
      mergeQuickLeadBodyParam = {
        customer_id: "",
        car_lead_id: "",
        ql_lead_id: ""
      };
      if (firstLead && firstLead.id > 0) {
        checkLeadMergeParam = {
          customer_id: firstLead.customer_id,
          car_lead_id: firstLead.id
        };

        Promise.all([
          LeadService.checkLeadMerge(checkLeadMergeParam),
          MasterService.getTaskResponse({ lead_id: firstLead.id }),
          PolicyService.getPreviousPolicyDetails({
            lead_id: firstLead.id
          }),
          LeadService.getCustomerLeadsForMerge(checkLeadMergeParam),
        ]).then((data) => {
          // console.log(data[2])
          // getTaskStatus(firstLead.id);

          // reset all the model values involved in validation

          // userTask.value.value = null;
          // userTask.value.options = [];
          // userTask.value.options = store.getters.getUserTaskResponse?.statuses;
          // dealTask.value.value = null;
          // dealTask.value.options = [];
          // dealTask.value.options =
          //   store.getters.getUserTaskResponse?.deal_statuses;
        });
      }

      MasterService.getDocumentTypes({
        categories: "standard"
      });
    });
    const leadIdParam = computed(() => {
      return router.currentRoute.value.query.lead_id;
    });
    

    const checkLeadMergeStatus = (customer_id, lead_id, lead) => {
      checkLeadMergeParam = {
        customer_id: customer_id,
        car_lead_id: lead_id
      };
      vType.value = lead.vehicle_type
      LeadService.checkLeadMerge(checkLeadMergeParam);
    };
    const getPolicyDocuments = (policy_id, lead_id) => {
      policyBodyParam.policy_id = policy_id;
      policyBodyParam.lead_id = lead_id;
      PolicyService.getAllDocuments(policyBodyParam);
      PolicyService.getPolicyDocuments(policyBodyParam);
      PolicyService.getTempPolicyDocuments(policyBodyParam);
    };
    
 
  

 
    const selectedLead = ref();
    const leadListRef = ref()
    const getLeadTaskList = (customer_id, lead_id, lead = "") => {
      ct.isOpen = true
      selectedLead.value = lead;
 
      let taskParameters = {
        customer_id:customer_id,
        lead_id : lead_id
      };
   
      TaskService.getLeadTaskList(taskParameters);
      MasterService.getTaskResponse({ lead_id: lead_id });
      leadListRef.value.toFirst()
    };
    const getDriverDetailsList = (customer_id, lead_id = -1) => {
      quotesBodyParam.customer_id = customer_id;
      quotesBodyParam.lead_id = lead_id;
      QuotesService.getDriverDetailsList(quotesBodyParam);
    };
    const getLeadLogs = (lead_id, customer_id) => {
      leadStatusBodyParm.car_lead_id = lead_id;
      leadStatusBodyParm.customer_id = customer_id;
      LeadService.getLeadActivityLogs(leadStatusBodyParm);
      LeadService.getLeadStatusLogs(leadStatusBodyParm);
    };

    const getLeadPolicies = (lead_id, customer_id) => {
      PolicyService.getLeadPolicies({
        lead_id: lead_id,
        customer_id: customer_id
      });
    };
    

    const onChangeQuickLead = (event) => {
      mergeQuickLeadBodyParam.car_lead_id = event;
    };
    const onChangeMergeLead = (event) => {
      mergeLeadBodyParam.value.new_car_lead_id = checkLeadMergeParam.car_lead_id;
      // mergeLeadBodyParam.car_lead_id = event;
    };

    let vType = ref(1);
    let car_year = ref(0);
    let manufacturer_id = ref(1);
    let model_id = ref(1);
    let trim_level_id = ref(1);
    let clead_id = ref(1);
    const showMergeQuickLeadModal = (customer_id, lead_id, lead) => {
      clead_id.value = lead_id;
      mergeQuickLeadBodyParam.customer_id = customer_id;
      mergeQuickLeadBodyParam.ql_lead_id = lead_id;
      vType.value = lead.vehicle_type;
      car_year.value = lead.car_year;
      manufacturer_id.value = lead.manufacturer_id;
      model_id.value = lead.model_id;
      trim_level_id.value = lead.trim_level_id;

      setTimeout(() => {
        const quickLeadMerge:any = inst?.refs?.quickLeadMerge
        quickLeadMerge.focus()
      }, 500);
    };

    const closeQLModal = () => {
      isError.value = false;
      quicklead.value = 0;
      mergeQuickLeadBodyParam.car_lead_id = "";
      hideModal(transferPolicyModalRef1.value);
    };
    const closeLeadModal = () => {
      isError.value = false;
      hideModal(transferPolicyModalRef.value);
    };

    const mergeQuickLead = () => {
      // hideModal(transferPolicyModalRef1.value);
      isError.value = false;
      if (mergeQuickLeadBodyParam.car_lead_id != '' && mergeQuickLeadBodyParam.car_lead_id != '0') {
        CustomerService.mergeQuickLead(store, mergeQuickLeadBodyParam);
        closeQLModal();
      } else {
        isError.value = true;
      }
    };

    const isError = ref<boolean>(false);
    const mergeLead = () => {
      //  hideModal(transferPolicyModalRef.value);
      isError.value = false;
      if (mergeLeadBodyParam.value.new_car_lead_id == 0) {
        isError.value = true;
      } else {
        Promise.all([LeadService.mergeLead(mergeLeadBodyParam.value)]).then(
          (data) => {
            CustomerService.getCustomerDetails(
              store,
              checkLeadMergeParam.customer_id
            );
            hideModal(transferPolicyModalRef.value);
          }
        );
      }
    };
    // const getTaskStatus = (lead_id) => {
    //   Promise.all([MasterService.getTaskResponse({ lead_id: lead_id })]).then(
    //     (data) => {
    //       userTask.value.value = null;
    //       userTask.value.options = [];
    //       userTask.value.options = store.getters.getUserTaskResponse?.statuses;
    //       dealTask.value.value = null;
    //       dealTask.value.options = [];
    //       dealTask.value.options =
    //         store.getters.getUserTaskResponse?.deal_statuses;
    //       taskRules.value.disposition_id.show = false;
    //       taskRules.value.lost_lead_reason.show = false;
    //       taskRules.value.followup_time.show = false;
    //       taskRules.value.task_notes.show = false;
    //       notes.value = "";
    //       dueDateAt.value = moment().format('YYYY-MM-DD HH:mm');
    //       taskParameters.due_date = "";
    //       taskParameters.disposition_id = "";
    //       taskParameters.task_notes = "";
    //       taskParameters.lost_lead_reason_id = -1;
    //       policyTaskStatus.value.value = null;
    //       policyTaskStatus.value.options = [];
    //       policyTaskStatus.value.options = store.getters.getUserTaskResponse?.policy_task_statuses;
    //       // taskParameters.closed_lead = null;
    //     }
    //   );
    // };
    

    let policyDocuments = computed(() => {
      return store.getters.getPolicyDocs;
    });
    let getLeadToMergeStatus = computed(() => {
      return store.getters.getLeadToMergeStatus;
    });
    let showLeadListDom = computed(() => {
      return store.getters.getShowLeadDomStatus;
    });
    let getUserTaskResponse = computed(() => {
      return store.getters.getUserTaskResponse;
    });

    // computed: {
    //   input_disabled() {
    //       return data.properties[3] ? false : true;
    //   }
    const policy_list = computed(() => {
      return store.state.PolicyModule.PreviousPolicyStatus;
    });

    const isOpen = ref<boolean>(false);
    const currentIndex = ref();
    const openSasame = (lead, e) => {
      (lead.is_open = true),
        (currentIndex.value = currentIndex.value != e ? e : null);
    };

    const count = ref(0);

    const popoverValue = ref<Array<number>>([11, 17]);

    const nameCount = (a: any, b: any, c: any, d: any) => {
      return a.length + b.length + c.length + d?.length;
    };

    const policyName = computed(() => {
      const datas = document.getElementById("policy-name");
      return datas?.style.textOverflow;
    });

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    const setLoadingTrue = () => {
      store.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
      store.commit(Mutations.SET_ALL_POLICY_DOCUMENTS, []);
    };

    onBeforeUnmount(() => {
      ct.currentLead = 0
    })

    const mergeQuickLeadF = (customer_id, lead_id, lead) => {
      clead_id.value = lead_id;
      mergeQuickLeadBodyParam.customer_id = customer_id;
      mergeQuickLeadBodyParam.ql_lead_id = lead_id;
      vType.value = lead.vehicle_type;
      car_year.value = lead.car_year;
      manufacturer_id.value = lead.manufacturer_id;
      model_id.value = lead.model_id;
      trim_level_id.value = lead.trim_level_id;

      setTimeout(() => {
        const mergeSelect:any = inst?.refs?.mergeSelect
        mergeSelect.focus()
      }, 500);
    }

    const quicklead = ref(0)

    const closintTime = (e) => {
      if(e.which === 27) {
        closeQLModal()
        closeLeadModal()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closintTime);
    })

    const getQuickCarLeads = computed(() => {
      return store.getters.getLeadsForMerge.filter(x => x.vehicle_type === vType.value && ![12,13,9,14].includes(x.lead_status_id));
    })

    const getMatchLead = computed(() => {
      return store.getters.getLeadsForMerge.filter(x => x.vehicle_type === vType.value
       && x.car_year === car_year.value && x.manufacturer_id === manufacturer_id.value 
       && x.model_id === model_id.value && x.trim_level_id === trim_level_id.value &&
       x.id !== clead_id.value);
    })

    const listByCar = computed(() => {
      if(props && props.list !== undefined) {
        return props.list.filter((x: any) => x.vehicle_type === 1)
      }

      return [];
    })

    const listByBike = computed(() => {
      if(props && props.list !== undefined) {
        return props.list.filter((x: any) => x.vehicle_type === 2)
      }

      return [];
    })

    const lddExplore = ref()
    const plExlore = ref()

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    return {
      notOmMAs,
      lddExplore,
      plExlore,
      getQuickCarLeads,
      quicklead,
      mergeLeadBodyParam,
      mergeQuickLeadF,
      leadListRef,
      manufacturer_id,
      car_year,
      trim_level_id,
      model_id,
      clead_id,
      listByCar,
      listByBike,
      ct,
      getMatchLead,
  
      
    
      // myTime,
      setLoadingTrue,
      getTextWidth,
      policyName,
      
      selectedLead,
      statusModal,
        
      nameCount,
      popoverValue,
      count,
      isOpen,
      currentIndex,
      openSasame,
      leadStatusMixin,
      transferPolicyModalRef,
      transferPolicyModalRef1,
      closeQLModal,
      closeLeadModal,
      isError,
      policy_list,
      validateDueDate,
      customerResponseValue,
      lostReasonValue,
      dueDateAt,

      getDriverDetailsList,
      mergeQuickLead,
      onChangeQuickLead,
      getLeadTaskList,
      showMergeQuickLeadModal,
      getLeadLogs,
      policyDocuments,
      getLeadPolicies,
      getPolicyDocuments,
      getLeadList,
      getLeadToMergeStatus,
      checkLeadMergeStatus,
      showLeadListDom,
      onChangeMergeLead,
      getUserTaskResponse,
      mergeLead,
      validateDisposition,
    
      // getTaskStatus,
      taskRules,
      isTaskFormValid,
      leadIdParam,
      LeadList,
      vType,
      otherGenders
    };
  }
});
