
import { defineComponent, computed, getCurrentInstance, ref, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
import CustomerService from "@/core/services/car/CustomerService";
import TaskService from "@/core/services/car/TaskService";
import LeadService from "@/core/services/car/LeadService";

import Multiselect from "@vueform/multiselect";
import moment from "moment";
import ReusableModal from "@/components/modals/forms/ReusableModal.vue";


interface TaskBodyParam {
  customer_id: number;
  lead_id: number;
  due_date: string;
  disposition_id: any;
  task_notes: string;
  vehicle_type: string;
  agent: number;
  lost_lead_reason_id: number;
  // closed_lead: any | null;
}

export default defineComponent({
  name: "task-details",
  components: {
    Multiselect,
    ReusableModal
  },
  props: {
    lead: {
        type: Object,
        required: true
    },
    leads: {
        type: Object,
        required: true
    },
    disabledButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {

    const store = useStore();
    const inst = getCurrentInstance()
    const taskRules = ref({
      disposition_id: {
        show: false,
        message: "Please select the Customer Response"
      },
      lost_lead_reason: {
        show: false,
        message: "Please enter Lost Lead Reason"
      },
      followup_time: {
        show: false,
        message: "Please select Follow up due date & time"
      },
      task_notes: {
        show: false,
        message: "Please enter Notes"
      },
      closed_lead: {
        show: false,
        message: "Please select closed lead option"
      }
    });
    let isTaskFormValid = ref<boolean>(true);
    let customerResponseValue = ref<any>("");
    let lostReasonValue = ref<string>("");
    let dueDateAt = ref<any>();
    let dueTimeAt = ref<string>("");
    let closedLead = ref(null);
    let taskParameters = {} as TaskBodyParam;
    taskParameters.lost_lead_reason_id = -1;

    onMounted(() => {
        dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1')
        dueTimeAt.value = dueDateTime('HH:mm','1')
        getTaskStatus(props.lead.id);

        
    });


    // watchEffect(() => {
    //   if(userTaskList.value)
    //   {
    //     let responses = userTaskList.value.statuses;
        
    //     if(responses && props.leads.length <= 1){
    //         // BND-2033 : filter out redudant lead if only single lead present
    //         responses = responses.filter(x => x.value != 17)
    //     }

    //     console.log('responses',responses) 
    //     if(responses)
    //       userTaskList.value.statuses = responses;
    //   }
    // })

    let userTaskList = computed(() => {
      return store.getters.getUserTaskResponse;
    });

    let userTaskReason = computed(() => {
      return store.getters.getUserTaskReasons;
    });

    const userTask = ref({
      placeholder: "Please Select Response",
      value: null,
      options: userTaskList.value.statuses,
      searchable: true,
      clearOnSelect: true
    });
    const dealTask = ref({
      placeholder: "Please Select Response",
      value: null,
      options: userTaskList.value.deal_statuses,
      searchable: true,
      clearOnSelect: true
    });

    const policyTaskStatus = ref({
      placeholder: "Please Select Response",
      value: null,
      options: userTaskList.value.policy_task_statuses,
      searchable: true,
      clearOnSelect: true
    });

 
    const cancelTaskStatus = ref({
      placeholder: "Please Select Response",
      value: null,
      options: userTaskList.value.cancel_statuses,
      searchable: true,
      clearOnSelect: true
    });

    const taskReason = ref({
      placeholder: "Please Select Lost Reason",
      value: null,
      options: userTaskReason,
      searchable: true,
      clearOnSelect: true
    });

    const dueDateTime = (format,hours='')=>{
      return (hours) ? moment().add(hours,'hours').format(format)
                     : moment().format(format);
    }

    const onCustomerResponseChange = (event) => {
      taskRules.value.disposition_id.show = false
      taskRules.value.lost_lead_reason.show = false
      taskParameters.disposition_id = event;
      customerResponseValue.value = event;

      if (taskParameters.disposition_id != 8) {
        closedLead.value = null;
        // taskParameters.closed_lead = null;
        taskRules.value.closed_lead.show = false;
      }
      /**
       * Adding 1 hour to the current date time
       */
      if([8, 11, 17].includes(customerResponseValue.value)){
        dueTimeAt.value = dueDateTime('H:mm');
        dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm')
      }else{
        dueTimeAt.value = dueDateTime('H:mm','1');
        dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
      }

      taskReason.value.value = null;
      Promise.all([MasterService.getTaskReasons({ task_status: event })]).then(
        (data) => {
          // taskReason.value.options = null;
          // validateTaskForm();
        }
      );
      
    };

    const onLostLeadResponseChange = (event) => {
      taskParameters.lost_lead_reason_id = event;
    };
    const dueDatePicker = ref();
    const getDueDateTime = (dateTime) => {

      taskRules.value.followup_time.show = false
      dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
      dueTimeAt.value = dueDateTime('H:mm','1');
      taskParameters.due_date = dueDateTime('YYYY-MM-DD HH:mm','1');
 
    };

    let notes = ref<string>("");

    const loading = ref<boolean>(false);
    const addTask = (lead) => {
      isTaskFormValid.value = true;
      

      taskParameters.task_notes = notes.value;
      taskParameters.agent = lead.agent;
      taskParameters.lead_id = lead.latest_task.lead_id;
      taskParameters.customer_id = lead.customer_id;
      taskParameters.vehicle_type = lead.vehicle_type;
      taskParameters.due_date = dueDateAt.value
  
      validateTaskForm(lead);
  

      if (isTaskFormValid.value) {
        loading.value = true;
        CustomerService.addLeadTask(store, taskParameters)
        .then((data: any) => {
            CustomerService.getCustomerDetailsNoRefresh(
              store,
              taskParameters.customer_id
            );
            TaskService.getLeadTaskList(taskParameters);
            // window.location.reload();
            resetFormValue();
            loading.value = false;
            closeModal();
            taskParameters.disposition_id = null;
            customerResponseValue.value = null;
            const messages = ['Redundant lead deleted successfully', 'False lead deleted successfully']
            if(props && props.leads.length === 1 && data &&  messages.includes(data.message)){
              window.location.reload()
            }
          }
        ).catch((err) => {
          loading.value = false;
          closeModal();
        });
      } else {
        focusField()
        loading.value = false;
      }
    };

    const validateTaskForm = (lead) => {
      if (taskParameters.disposition_id == "") {
        taskRules.value.disposition_id.show = true;
        isTaskFormValid.value = false;
      }
      if (
        [8,11,19].includes(taskParameters.disposition_id)
      ) {
        if (
          taskParameters.lost_lead_reason_id == null ||
          taskParameters.lost_lead_reason_id == -1
        ) {
          taskRules.value.lost_lead_reason.show = true;
          isTaskFormValid.value = false;
        }
      }

      if (
        taskParameters.disposition_id == 12 ||
        taskParameters.disposition_id == 13
      ) {
        if (taskParameters.task_notes == "") {
          taskRules.value.task_notes.show = true;
          isTaskFormValid.value = false;
        }
        if (taskParameters.task_notes.length > 255) {
          taskRules.value.task_notes.show = true;
          taskRules.value.task_notes.message =
            "Max  255 characters are allowed";
          isTaskFormValid.value = false;
        }
      }

      if (
        (dueDateAt.value == "" ||
          dueDateAt.value == null ||
          dueDateAt.value == undefined) &&
        taskParameters.disposition_id != 8 &&
        taskParameters.disposition_id != 11 &&
        taskParameters.disposition_id != 17 &&
        taskParameters.disposition_id != 18
      ) {
        taskRules.value.followup_time.show = true;
        isTaskFormValid.value = false;
      }
      if (
        taskParameters.disposition_id == 8 &&
        lead.is_show_closed_lead &&
        closedLead.value == null
      ) {
        taskRules.value.closed_lead.show = true;
        isTaskFormValid.value = false;
      }

     
    };
    const resetFormValue = () => {
      taskParameters.disposition_id = "";
      customerResponseValue.value = "";
      taskReason.value.value = null;
      lostReasonValue.value = "-1";
      taskParameters.lost_lead_reason_id = -1;
      userTask.value.value = null;
      policyTaskStatus.value.value = null;
      dealTask.value.value = null;
      cancelTaskStatus.value.value = null;
      closedLead.value = null;
      notes.value = "";
      dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
      dueTimeAt.value = dueDateTime('HH:mm','1');
      taskRules.value.disposition_id.show = false;
      taskRules.value.lost_lead_reason.show = false;
      taskRules.value.followup_time.show = false;
      taskRules.value.task_notes.show = false;
      // userTask.value=null;
    };

    const focusField = () => {
      const { disposition_id, lost_lead_reason, followup_time, task_notes } = taskRules.value

      if(disposition_id.show && !taskParameters.disposition_id) {
        const responseFromCustomerRef:any = inst?.refs.responseFromCustomerRef
        responseFromCustomerRef.forEach(customerRef => {
          customerRef.focusInput()
        })
      }

      if(!disposition_id.show && lost_lead_reason.show) {
        const lostLeadRef:any = inst?.refs.lostLeadRef
        lostLeadRef.forEach(lostRef => {
          lostRef.focusInput()
        })
      }

      if(!disposition_id.show && followup_time.show) {
        const dueDatePicker:any = inst?.refs.dueDatePicker
        dueDatePicker.forEach(dueRef => {
          dueRef.isFocus()
        })
      }

      if(!disposition_id.show  && (!followup_time.show || !lost_lead_reason.show) && task_notes.show) {
        const notesRef:any = inst?.refs.notesRef
        notesRef.forEach(noteRef => {
          noteRef.focus()
        })
      }
    }

    const toTaskNotes = () => {
      const notesRef:any = inst?.refs.notesRef
        notesRef.forEach(noteRef => {
          noteRef.focus()
        })
        if(dueDateAt.value) {
          taskRules.value.followup_time.show = false
          taskParameters.due_date = moment(dueDateAt.value).format("YYYY-MM-DD HH:mm");
        }
    }

    const dateTimeBack = () => {
      const lostLeadRef:any = inst?.refs.lostLeadRef
      const responseFromCustomerRef:any = inst?.refs.responseFromCustomerRef
        if(!lostLeadRef.disabled) {
          lostLeadRef.forEach(lostRef => {
            lostRef.focusInput()
          })
        } else {
          responseFromCustomerRef.forEach(customerRef => {
            customerRef.focusInput()
          })
        }
    }

    const dueDateBack = () => {
      
      const dueDatePicker:any = inst?.refs.dueDatePicker
          dueDatePicker.forEach(dus => {
            dus.isFocus()
          })
    }

    const toButton = () => {
      const saveRef:any = inst?.refs.saveRef
          saveRef.forEach(save => {
            save.focus()
          })

    }

    const updateCloseLead = (lead) => {
      isTaskFormValid.value = true;
      loading.value = true;

      taskParameters.task_notes = notes.value;
      taskParameters.agent = lead.agent;
      taskParameters.lead_id = lead.id;
      taskParameters.customer_id = lead.customer_id;
      taskParameters.due_date = dueDateAt.value
        // dueDateAt.value == "" ? "" : taskParameters.due_date;
      validateTaskForm(lead);
      // if(taskParameters.closed_lead == '1' && isTaskFormValid.value)
      // {
      //   //show popup
      // }

      if (isTaskFormValid.value) {
        Promise.all([LeadService.updateClosedLead(taskParameters)]).then(
          (data) => {
            CustomerService.getCustomerDetails(
              store,
              taskParameters.customer_id,
              false
            );
            TaskService.getLeadTaskList(taskParameters);
            window.location.reload();
            resetFormValue();
            loading.value = false;
            isOpenDialog.value = false;
            closeModal();
          }
        );
      } else {
        loading.value = false;
      }
    };

    const changeCloseLead = (event) => {
      // taskParameters.closed_lead = event.target.value;
      closedLead.value = event.target.value;
      // console.log("changeCloseLead", taskParameters);
      // console.log("event.target.value", event.target.value);
      taskRules.value.closed_lead.show = false;
    };

    const today = computed(() => {
      var ddate = new Date();
      ddate.setDate(ddate.getDate() - 1);
      return new Date(ddate).getTime();
    });

    const disableToday = computed(() => {
      const myDate = new Date(today.value)
      myDate.setDate(myDate.getDate() + 1)
      return moment(myDate).format('YYYY-MM-DD')
    })

    const disabledDate = (time: Date) => {
      return time.getTime() < today.value;
    };
    const popoverValue = ref<Array<number>>([11, 17]);
    const isOpenDialog = ref(false);
    const itemLead = ref<any>(null);
    const modalTitle = ref<string>("");
    const modalMessage = ref<string>("");
    const reusableModal = ref()
    const openModal = (item, isAddTask) => {
      itemLead.value = {
        lead: item,
        isAdd: isAddTask
      };
      modalTitle.value = "Task Details";
      modalMessage.value = isAddTask
        ? "Are you sure this will delete the lead permanently?"
        : "Are you sure you want to close the lead";
      isOpenDialog.value = true;
      reusableModal.value.toFirst()
    };

    const processData = (item) => {
      if (item.isAdd) {
        addTask(item.lead);
      }

      if (!item.isAdd) {
        updateCloseLead(item.lead);
      }
    };

    const closeModal = () => {
      itemLead.value = null;
      isOpenDialog.value = false;
    };

    const closingLeads = computed(() => {
      if (!popoverValue.value.includes(customerResponseValue.value)) {
        return true;
      }
      if (customerResponseValue.value === 11 && taskReason.value.value) {
        return false;
      }

      if (customerResponseValue.value === 17 && !taskReason.value.value) {
        return false;
      }
      return true;
    });

    const getTaskStatus = (lead_id) => {
      Promise.all([MasterService.getTaskResponse({ lead_id: lead_id })]).then(
        (data) => {

          const otherLeadStatus = props.leads.filter((x) => {
              if(![1,2].includes(x.lead_status_id))
                  return x;
          });

          let responses = store.getters.getUserTaskResponse?.statuses;
        
          if(responses && props.leads.length <= 1){
              // BND-2033 : filter out redudant lead if only single lead present
              responses = responses.filter(x => x.value != 17)
          }

          if(responses && otherLeadStatus.length > 0){
              // BND-2033 : filter out false lead if other leads are present
              responses = responses.filter(x => x.value != 11)
          }
  
          console.log('responses',responses) 
            // if(responses)
            //   userTaskList.value.statuses = responses;

          userTask.value.value = null;
          userTask.value.options = [];
          userTask.value.options = responses;
          dealTask.value.value = null;
          dealTask.value.options = [];
          dealTask.value.options =
            store.getters.getUserTaskResponse?.deal_statuses;
          taskRules.value.disposition_id.show = false;
          taskRules.value.lost_lead_reason.show = false;
          taskRules.value.followup_time.show = false;
          taskRules.value.task_notes.show = false;
          notes.value = "";
          dueDateAt.value = dueDateTime('YYYY-MM-DD HH:mm','1');
          dueTimeAt.value = dueDateTime('HH:mm','1');
          taskParameters.due_date = "";
          taskParameters.disposition_id = "";
          taskParameters.task_notes = "";
          taskParameters.lost_lead_reason_id = -1;
          policyTaskStatus.value.value = null;
          policyTaskStatus.value.options = [];
          policyTaskStatus.value.options = store.getters.getUserTaskResponse?.policy_task_statuses;
          cancelTaskStatus.value.value = null;
          cancelTaskStatus.value.options = [];
          cancelTaskStatus.value.options = store.getters.getUserTaskResponse?.cancel_statuses;
         
        }
      );
    };

    const lostReason_disabled = computed(() => {
      return [8,11,19].includes(customerResponseValue.value)
        ? false
        : true;
    });

    const disableDueDate = computed(() => {
      return [8, 11, 17, 19].includes(customerResponseValue.value);
    });
    

    return {
        lostReason_disabled,
        disableDueDate,
        getTaskStatus,
        loading,
        userTaskList,
        policyTaskStatus,
        cancelTaskStatus,
        userTask,
        dealTask,
        taskReason,
        onCustomerResponseChange,
        taskRules,
        dueDateBack,
        toButton,
        dateTimeBack,
        onLostLeadResponseChange,
        getDueDateTime,
        addTask,
        notes,
        customerResponseValue,
        lostReasonValue,
        dueDateAt,
        taskParameters,
        isTaskFormValid,
        closingLeads,
        processData,
        closeModal,
        openModal,
        modalMessage,
        modalTitle,
        itemLead,
        isOpenDialog,
        today,
        disabledDate,
        disableToday,
        changeCloseLead,
        updateCloseLead,
        reusableModal,
        closedLead,
        dueTimeAt
    }
  }
  
});
