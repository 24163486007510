
import { computed, defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import TaskService from "@/core/services/car/TaskService";
import LeadService from "@/core/services/car/LeadService";
import { useStore } from "vuex";
import moment from "moment";
// interface CustomerStatusLogsBodyParam {
//     lead_id: number;
// }
interface LeadStatusBodyParm {
  car_lead_id: number;
  customer_id: number;
}
export default defineComponent({
  name: "lead-logs-explore",
  components: {},
  props: {
    leadActivityList: Array,
    leadStatusList: Array,
    leadDetails: Array
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"]
    };
  },
  setup() {
    const store = useStore();
    let isActive = false;
    let leadStatusBodyParm = {} as LeadStatusBodyParm;
    const getLeadLogs = (lead_id, customer_id) => {
      leadStatusBodyParm.car_lead_id = lead_id;
      leadStatusBodyParm.customer_id = customer_id;
      LeadService.getLeadActivityLogs(leadStatusBodyParm);
      LeadService.getLeadStatusLogs(leadStatusBodyParm);
    };

    // let customerStatusLogsBodyParam = {} as CustomerStatusLogsBodyParam;
    onMounted(() => {
      //
    });
    // const getData = computed(() => {
    //   return props.customerStatusLogList ? true : false;
    // });
    const showLeadLogDom = computed(() => {
      return store.getters.getLeadLogDomStatus;
    });

    const setDate = (e) => {
      return moment(e, "DD/MM/YYYY HH:mm")
        .zone("+0800")
        .format("DD/MM/YYYY HH:mm");
    };

    const nameCount = (a: any, b: any, c: any, d: any) => {
      return a.length + b.length + c.length + d?.length;
    };

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      // getData,
      closeRef,
      closeModal,
      nameCount,
      isActive,
      getLeadLogs,
      showLeadLogDom,
      setDate,
      getTextWidth
    };
  }
});
