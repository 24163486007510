
import { computed, defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import moment from "moment";
import store from "@/store";
// import TaskService from "@/core/services/car/TaskService";

interface CustomerStatusLogsBodyParam {
  lead_id: number;
}

export default defineComponent({
  name: "customer-logs-explore",
  components: {},
  props: {
    customerDetails: Object,
    customerActivities: Array,
    customerStatusLogList: Array,
    //  customerActivities:Array,
    //  customerStatus:Array,
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup(props) {
    let isActive = false;

    let customerStatusLogsBodyParam = {} as CustomerStatusLogsBodyParam;
    onMounted(() => {
      //
    });
    const getData = computed(() => {
      return props.customerStatusLogList ? true : false;
    });
    const logs = computed(() => {
      const data = store.getters.getCustomerActivityLogList;
      return data.map((val) => ({
        action_by: val.action_by,
        activity_desc: val.activity_desc,
        activity_description: val.activity_description,
        activity_id: val.activity_id,
        activity_slug: val.activity_slug,
        created_at: val.created_at,
        created_date: val.created_date,
        customer_id: val.customer_id,
        description: JSON.parse(val.description),
        user_id: val.user_id,
        id: val._id,
      }));
    });

    const viewedLogs = computed(() => {
      const dataViewed = store.getters.getCustomerViewActivityLogList;
      return dataViewed.map((val) => ({
        action_by: val.action_by,
        activity_desc: val.activity_desc,
        activity_description: val.activity_description,
        activity_id: val.activity_id,
        created_at: val.created_at,
        created_date: val.created_date,
        customer_id: val.customer_id,
        description: JSON.parse(val.description),
        user_id: val.user_id,
        id: val._id,
      }));
    });

    const setDate = (e) => {
      return moment(e).zone("+0400").format("DD/MM/YYYY HH:mm");
    };
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      closeRef,
      closeModal,
      logs,
      getData,
      isActive,
      setDate,
      viewedLogs,
    };
  },
});
