
import { computed, defineComponent, ref, onMounted, onBeforeMount, getCurrentInstance } from "vue";
import QuotesService from "@/core/services/car/QuotesService";
import { useStore } from "vuex";
import ManualQuotes from "@/components/partials/manual-quotes/ViewManualQuotes.vue";
// import DocumentLogsModal from '@/components/partials/logs/DocumentLogsModal.vue';
import { hideModal,removeModalBackdrop } from "@/core/helpers/dom";
import mainSocket from "./../../main-socket";
import router from "@/router";

interface ManualQuotesBodyParm {
  customer_id: number;
  reload: boolean
}
export default defineComponent({
  name: "manual-quotes-explore",
  components: { 
    ManualQuotes,
    
    
  },
  props: {
    manualQuotes: Array,
    customerDetails: Array,
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup() {
    const store = useStore();
    let isActive = false;
    let manualQuotesBodyParm = {} as ManualQuotesBodyParm;
    const quoteModal = ref<null | HTMLElement>(null);
    const currentUserId = store.getters.currentUser.id;

    const getManualQuotes = (customer_id) => {
      manualQuotesBodyParm.customer_id = customer_id;
      // console.log("manualQuotesBodyParm.customer_id", customer_id);
      QuotesService.getManualQuotes(manualQuotesBodyParm);
    };

    onMounted(async() => {
      const user = JSON.parse(window.localStorage.getItem('user') || '')

      // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
      manualQuotesBodyParm.customer_id = parseInt(router.currentRoute.value.params.customerId.toString())
      await mainSocket
        .channel("channel-" + user.id)
        .listen("ProvideQuoteSentSocketEvent", async (response) => {
            if (response.status) {
                // console.log('notifs');
                manualQuotesBodyParm.reload = false
                await QuotesService.getManualQuotes(manualQuotesBodyParm);
                removeModalBackdrop()
                // hideModal(quoteModal.value);
            }
        });
    });
    const showManualQuotesDom = computed(() => {
      return store.getters.getManualQuotesDomStatus;
    });

    const documentLog = computed(() => {
      return store.getters.getDocumentLogs;
    })
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        const generateModalRef:any = inst?.refs?.generateModalRef
        generateModalRef.focus()
      }, 500);
    }

    const pushTOLink = (id) => {
        closeRef.value.click()

      router.push({
        name: 'generate-manaual-quote',
              params: { customerId: id },
      })
    }

    return {
      pushTOLink,
      toFirst,
      closeRef,
      closeModal,
      isActive,
      quoteModal,
      getManualQuotes,
      showManualQuotesDom,
      documentLog,
    };
  },
});
