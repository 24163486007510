
import { computed, defineComponent, ref, onMounted, onBeforeMount, getCurrentInstance } from "vue";
import QuotesService from "@/core/services/car/QuotesService";
import { useStore } from "vuex";
import ViewEmail from "@/components/partials/emails/View-Email.vue";
import AddEmailModal from "@/components/modals/forms/NewEmailModal.vue";
import EmailService from "@/core/services/car/EmailService";
import { useRoute, useRouter } from "vue-router";
interface ManualQuotesBodyParm {
  customer_id: number;
}
export default defineComponent({
  name: "email-explore",
  components: { ViewEmail, AddEmailModal },
  props: {
    emailList: Array,
    customerDetails: Array,
    showEmailListModal: Boolean
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup() {
    const store = useStore();

    const route = useRoute();
    let isActive = false;
    const inst = getCurrentInstance()
    let manualQuotesBodyParm = {} as ManualQuotesBodyParm;

    const getManualQuotes = (customer_id) => {
      manualQuotesBodyParm.customer_id = customer_id;
      // console.log("manualQuotesBodyParm.custDomer_id", customer_id);
      // QuotesService.getManualQuotes(manualQuotesBodyParm);

      // console.log(route.params.customerId);
    };

    onMounted(() => {
      //
    });
    const showManualQuotesDom = computed(() => {
      return store.getters.getManualQuotesDomStatus;
    });
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })
    
    const addEmailRef = ref()
    const handleNewEmail = () => {
      // addEmailRef.value.toFirst()
    }
   
    const toFirst = () => {
      setTimeout(() => {
        const emailFocus:any = inst?.refs?.emailFocus
        emailFocus.focus()

      }, 500);
    }

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    return {
      notOmMAs,
      toFirst,
      addEmailRef,
      handleNewEmail,
      closeRef,
      closeModal,
      isActive,
      getManualQuotes,
      showManualQuotesDom,
    };
  },
});
