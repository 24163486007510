
import { defineComponent, onMounted, ref, computed } from "vue";
import QuotesService from "@/core/services/car/QuotesService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import MarkFlowDocuments from "@/components/modals/forms/MarkFlowDocuments.vue";
import PolicyService from "@/core/services/car/PolicyService";
import { customFunction } from "@/store/stateless/store";

interface ManualQuotesParam {
  quotation_id: number;
}
interface IDocBodyParam {
    manual_quotation_id: any;
    quote_request_id: any;
    lead_id: any;
}

interface DownloadDocBodyParam{
  manual_quote_mark:  Array<any>;
}

interface ManualQuotesBodyParm {
  customer_id: number;
}

export default defineComponent({
  name: "manual-quotes-partials",
  components: {
    MarkFlowDocuments,
  },
  props: {
    manualQuotes: Object,
    customerDetails: Object
  },
  data() {
    return {};
  },
  setup(props) {
    const store = useStore();
    let manualQuotesParam = {} as ManualQuotesParam;
    let docBodyParam = {} as IDocBodyParam;
    let downloadDocBodyParam = {} as DownloadDocBodyParam;
    let manualQuotesBodyParm = {} as ManualQuotesBodyParm;
    

    const getManualQuotesDomStatus = computed(() => {
      return store.getters.getManualQuotesDomStatus;
    });

    const sendManualQuotes = (quotation_id) => {
      manualQuotesParam.quotation_id = quotation_id;
      QuotesService.sendManualQuotes(manualQuotesParam);
    };
    const documentLog = computed(() => {
      return store.getters.getDocumentLogs;
    });
    const ApiPath = process.env.VUE_APP_API_URL;

    let quoteRequestDocuments = computed(() => {
      return store.getters.getQuoteRequestDocument;
    });

    const getManualQuoteMarkFlowDocuments = (manual_quotation_id) => {
        docBodyParam.manual_quotation_id = manual_quotation_id;
        QuotesService.callingQuoteRequestDocs(docBodyParam);
    };

    const downloadZip = (manualQuote) => {
      let manualQuoteDocsArray = Array<any>();
        manualQuote.quote_request_doc.forEach((quoteReqDoc) =>{
            manualQuoteDocsArray.push(quoteReqDoc.id);
      });
      downloadDocBodyParam.manual_quote_mark = manualQuoteDocsArray;
      // console.log(downloadDocBodyParam);
      PolicyService.downloadZip(downloadDocBodyParam);
    };

    const sendMarkFlowDocsToWhatsApp = (manualQuote, customerWhatsAppNo) => {
      let manualQuoteDocsArray = Array<any>();
      manualQuote.quote_request_doc.forEach((quoteReqDoc) =>{
        manualQuoteDocsArray.push(quoteReqDoc.encrypted_file_path);
      });
      let docFiles = manualQuoteDocsArray.join("%0a%0a");
      let whatsAppURL = "https://wa.me/" + customerWhatsAppNo + "?text=" + docFiles + " -BuyAnyInsurance";
      // console.log(whatsAppURL);
      window.open(whatsAppURL, '_blank');
    }

    const redirectToWhatsapp = (customerWhatsAppNo, docFiles) => {
      
      let message = docFiles + " -BuyAnyInsurance";
      
      let whatsAppURL = "https://wa.me/" + customerWhatsAppNo + "?text=" + message;
      window.open(whatsAppURL, '_blank');
      
    }

    return {
      redirectToWhatsapp,
      getManualQuotesDomStatus,
      sendManualQuotes,
      ApiPath,
      documentLog,
      getManualQuoteMarkFlowDocuments,
      quoteRequestDocuments,
      downloadZip,
      sendMarkFlowDocsToWhatsApp,
      customFunction,
    };
  }
});
