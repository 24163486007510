
import { defineComponent, onMounted, toRef, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "view-email-detail",
  props: {
    emailData: Object,
  },
  setup(props) {
    const store = useStore();
    const email = toRef(props, 'emailData');

    onMounted(() => {
      //
    });

    return {
      email
    };
  },
});
