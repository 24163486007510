
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import NotesService from "@/core/services/car/NotesService";
import { hideModal } from "@/core/helpers/dom";
// interface KTCreateCustomer extends Customer {}
interface NotesBodyParam {
  lead_id: number;
  notes: string;
}
export default defineComponent({
  name: "add-notes-modal",
  components: {},
  props: {
    leadDetails: Array,
    leadId: Number,
  },
  setup(props) {
    let notesParameters = {} as NotesBodyParam;
    const addNotesModalRef = ref<null | HTMLElement>(null);

    let notes = ref<string>("");
    const isEmpty = ref(false);
    const addNotes = (lead_id) => {
      isEmpty.value = false
      notesParameters.notes = notes.value;
      notesParameters.lead_id = lead_id;
      if (notesParameters.notes != "") {
        //

        NotesService.addLeadNotes(notesParameters);
        hideModal(addNotesModalRef.value);
        resetFormValue();
      } else {
        isEmpty.value = true
        return false;
      }
    };
    //

    const resetFormValue = () => {
      notes.value = "";
      isEmpty.value = false
    };

     const closingTime = (e) => {
      if(e.which === 27) {
        resetFormValue()
      }
    }

      onBeforeMount(() => {
      window.addEventListener('keyup', closingTime);
    })

    return {
      closingTime,
      isEmpty,
      addNotes,
      notes,
      addNotesModalRef,
      resetFormValue,
    };
  },
});
