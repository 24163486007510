
import { defineComponent, onMounted, ref, computed } from "vue";
import QuotesService from "@/core/services/car/QuotesService";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import ViewEmailDetail from "@/components/partials/emails/View-Email-Detail.vue";


interface ManualQuotesParam {
  quotation_id: number;
}
export default defineComponent({
  name: "view-email",
  props: {
    emailList: Array,
    customerDetails: Object,
  },
  components: {
    ViewEmailDetail,
  },
  setup() {
    const store = useStore();
    const emailData = ref<any>(null);

    const viewEmailDetailModal = (email) => {
      emailData.value = email;
    };

    onMounted(() => {
      //
    });

    return {
      ViewEmailDetail,
      viewEmailDetailModal,
      emailData
    };
  },
});
