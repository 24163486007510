
import { computed, defineComponent,onMounted, getCurrentInstance, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
import EmailService from "@/core/services/car/EmailService";
import Multiselect from "@vueform/multiselect";
import IMultiselect from "@/models/IMultiselect";
interface IEmailParam {
  from_email: string;
  to_email: string;
  template: string;
  subject: string;
  message: string;
}
interface IEmailBodyParam {
  customer_id: any;
  email_template_id: string;
  from: string;
  to: string;
  bcc:string;
  subject: string;
  body: string;
}
export default defineComponent({
  name: "add-email-modal",
  components: { Multiselect },
  props: { customerDetails: Object },
  setup(props) {

    onMounted(() => {
        formData.value.to_email = selectedCustomer?.value.email;
        formData.value.from_email = loginUser?.value.email;
    })

    const formRef = ref<null | HTMLFormElement>(null);
    const addEmailModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let emailBodyParam = ref<IEmailBodyParam>({
      customer_id: "",
      email_template_id: "",
      from: "",
      to: "",
      bcc:"",
      subject: "",
      body: "",
    });
    const formData = ref<IEmailParam>({
      from_email: "",
      to_email: "",
      template: "",
      subject: "",
      message: "",
    });
    let emailTemplateList;
    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    // console.log(route.params.customerId);
    let emailTemplate = ref<IMultiselect>({
      placeholder: "Please Select Template",
      value: null,
      options: [],
      searchable: true,
      clearOnSelect: true,
    });
    Promise.all([EmailService.getEmailTemplate()]).then((data) => {
      emailTemplateList = computed(() => {
        return store.getters.getEmailTemplateList;
      });
      emailTemplate.value = {
        placeholder: "Please Select Template",
        value: null,
        options: emailTemplateList.value,
        searchable: true,
        clearOnSelect: true,
      };
    });

    const customRules = ref({
      from_email: {
        required: true,
        message: "From email is required",
        trigger: "change",
        show: false,
      },

      to_email: {
        required: true,
        message: "To email is required",
        trigger: "change",
        show: false,
      },

      template: {
        required: true,
        message: "Select Email Template",
        trigger: "change",
        show: false,
      },

      subject: {
        required: true,
        message: "Subject is required",
        trigger: "change",
        show: false,
      },
      message: {
        required: true,
        message: "Email message required",
        trigger: "change",
        show: false,
      },
    });
    let isFormValid = ref<boolean>(true);
    const showInvoiceDocumentDom = computed(() => {
      return store.getters.getInvoiceDocumentDom;
    });
    let loginUser = computed(() => {
      return store.getters.currentUser;
    });
    let selectedCustomer = computed(() => {
      return store.getters.getSelectedCustomerDetails;
    });
    // formData.value.to_email =
    const submit = () => {
      if (formData.value.to_email.trim() == "") {
        formData.value.to_email = selectedCustomer.value.email;
      }

      if (
        formData.value.to_email.trim() == "" ||
        formData.value.subject.trim() == "" ||
        formData.value.message.trim() == ""
      ) {
        isFormValid.value = false;
      } else {
        isFormValid.value = true;
      }

      if (!formRef.value) {
        validateForm();
        return;
      } else {
        // make API call for adding customer
        formData.value.template = emailTemplateList.value[0].value;
        if (isFormValid.value) {
          emailBodyParam.value = {
            customer_id: route.params.customerId,
            email_template_id: formData.value.template,
            from: loginUser?.value.email,
            to: formData.value.to_email,
            bcc: 'mark@yopmail.com',
            subject: formData.value.subject,
            body: formData.value.message,
          };
          // console.log(emailBodyParam.value);
          
          Promise.all([EmailService.sendEmail(emailBodyParam.value)]).then(
            (data) => {
              resetFormValue();
              hideModal(addEmailModalRef.value);
              EmailService.getEmailList({
                customer_id: route.params.customerId,
              });
            }
          );

          resetFormValue();
        } else {
          validateForm();
        }
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;
          }, 2000);
        } else {
          return false;
        }
      });
    };
    const onTemplateChange = (event) => {
      
      formData.value.template = event;
    };
    const resetFormValue = () => {
      formData.value.from_email = loginUser?.value.email;
      formData.value.to_email = " ";
      formData.value.subject = " ";
      formData.value.message = " ";
      formData.value.template = " ";
      customRules.value.from_email.show = false;
      customRules.value.to_email.show = false;
      customRules.value.subject.show = false;
      customRules.value.message.show = false;
      customRules.value.template.show = false;
    };
    const validateForm = () => {
      
      // console.log(emailTemplateList);
      if (formData.value.to_email.trim() == "") {
        customRules.value.to_email.show = true;
      }
      if (formData.value.subject.trim() == "") {
        customRules.value.subject.show = true;
      }
      if (formData.value.message.trim() == "") {
        customRules.value.message.show = true;
      }
    };

    const inst = getCurrentInstance()

    const toFirst = () => {
      setTimeout(() => {
        const subjectRef:any = inst?.refs?.subjectRef
        subjectRef.focus()
      }, 1000);
    }

    const TinyMceKey = process.env.VUE_APP_TINY_MCE_KEY;

    return {
      toFirst,
      loading,
      addEmailModalRef,
      showInvoiceDocumentDom,
      customRules,
      formRef,
      formData,
      submit,
      emailTemplate,
      onTemplateChange,
      loginUser,
      selectedCustomer,
      TinyMceKey,
      resetFormValue 
    };
  },
});
