
import { computed, defineComponent, getCurrentInstance, onBeforeMount, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
// import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import CustomerService from "@/core/services/car/CustomerService";

export default defineComponent({
  name: "update-email-modal",
  components: {},
  props: {
    showCategory: String,
    tempDocs: Array,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const updateContactInfoModalRef = ref<null | HTMLElement>(null);
    let ShowLoading = ref<boolean>(false);
    const rules = ref({
      other_contact_info: {
        show: false,
        message: "Contact Information is required",
      }
    });
    const otherContactInfo = ref<string>("");
    let isFormValid = ref<boolean>(true);


    const resetFormValue = () => {
      otherContactInfo.value = "";
      rules.value.other_contact_info.show = false;
      clearIsError()
    };
    const updateOtherContactInfo = () => {
      isFormValid.value = true;

    if (otherContactInfo.value == "") {
        rules.value.other_contact_info.show = true;
        isFormValid.value = false;
    } 
    else if(otherContactInfo.value != "" && otherContactInfo.value.length > 51){
        rules.value.other_contact_info.show = true;
        rules.value.other_contact_info.message = "Other contact info should be added 50 characters";
        isFormValid.value = false;
    }
    else {
        rules.value.other_contact_info.show = false;
    }

      if (isFormValid.value) {
        ShowLoading.value = true;
        Promise.all([
          CustomerService.updateOtherContactInfo({
            customer_id: route.params.customerId.toString(),
            other_contact_info: otherContactInfo.value,
          }),
        ])
          .then((data) => {
            resetFormValue();
            ShowLoading.value = false;
            hideModal(updateContactInfoModalRef.value);
            CustomerService.getCustomerDetailsNoRefresh(store, route.params.customerId.toString())
          })
          .catch((err) => {
            // console.log(err);
            hideModal(updateContactInfoModalRef.value);
          });
      }
    };


    const inst = getCurrentInstance()

    const toFirst = () => {
      setTimeout(() => {
        const otherContactInfoRef:any = inst?.refs?.otherContactInfoRef
        otherContactInfoRef.focus()
      }, 500);
    }

    const clearIsError = () => {
      rules.value.other_contact_info.show = false;
    }

    const closeModalRef = ref()
    const closeModal = (e) => {
      if(e.which === 27) {
        closeModalRef.value.click()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })
    
    return {
      closeModalRef,
      toFirst,
      updateOtherContactInfo,
      rules,
      resetFormValue,
      otherContactInfo,
      updateContactInfoModalRef,
      ShowLoading,
    };
  },
});
