
import { computed, defineComponent, onMounted, ref } from "vue";

import { useStore } from "vuex";
import ViewQuoteRequestDocuments from "@/components/partials/quote-request/ViewQuoteRequestDocuments.vue";
import QuotesService from "@/core/services/car/QuotesService";
import ManualQuotes from "@/components/partials/manual-quotes/ViewManualQuotes.vue";
import { notif } from '@/store/stateless/store';

interface IRequestPaymentLinkParams {
  doc_id: any;
  manual_quotation_id: any;
  quote_request_id: any;
}

interface IManualQuotesBodyParm {
  customer_id: number;
}

export default defineComponent({
  name: "mark-flow-documents-modal",
  components: { ViewQuoteRequestDocuments },
  props: {
    quoteRequestDocuments: Array,
  },
  setup(props) {
    const store = useStore();
    let requestPaymentBodyParams = {} as IRequestPaymentLinkParams;
    let manualQuotesBodyParm = {} as IManualQuotesBodyParm;

    const selectedPaymentDocId = ref(0);
    const selectedPaymentManualId = ref(0);
    const selectedPaymentQuoteId = ref(0);
    const selectedCustomerId = ref(0);

    const closeButton = ref();

    let loading = computed(() => {
      return store.getters.getQrLoading;
    });

    const selectedPaymentValue = (valArr) => {
      // console.log(valArr);
      selectedPaymentDocId.value = valArr.doc_id;
      selectedPaymentManualId.value = valArr.manual_quotation_id;
      selectedPaymentQuoteId.value = valArr.quote_request_id;
      selectedCustomerId.value = valArr.customer_id;
    }

    const requestQuotePaymentLink = (selectedPaymentDocId, selectedPaymentManualId, selectedPaymentQuoteId, selectedCustomerId) => {

      if(selectedPaymentDocId === 0) {
        notif.simple('Please Note', 'warning', 'Please select the quote', 0);
        return;
      }

      requestPaymentBodyParams.doc_id = selectedPaymentDocId;
      requestPaymentBodyParams.manual_quotation_id = selectedPaymentManualId;
      requestPaymentBodyParams.quote_request_id = selectedPaymentQuoteId;
      manualQuotesBodyParm.customer_id = selectedCustomerId;
      Promise.all([
        QuotesService.requestingPaymentLink(requestPaymentBodyParams),
      ]).then((data) => {
        QuotesService.getManualQuotes(manualQuotesBodyParm);
        closeButton.value.click();
      }).catch((error) => { console.log(error); });
    }

    const resetRadioCheck = ref();

    const resetData = () => {
      resetRadioCheck.value.resetbutton();
      selectedPaymentDocId.value = 0;
      selectedPaymentManualId.value = 0;
      selectedPaymentQuoteId.value = 0;
      selectedCustomerId.value = 0;
    }

    return {
      selectedPaymentDocId,
      selectedPaymentManualId,
      selectedPaymentQuoteId,
      selectedCustomerId,
      selectedPaymentValue,
      requestQuotePaymentLink,
      loading,
      resetData,
      resetRadioCheck,
      closeButton,
    };
  },
});
