
import { computed, defineComponent, ref, onMounted, onBeforeMount, getCurrentInstance } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";

import CustomerService from "@/core/services/car/CustomerService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import AddCloseTaskModal from "@/components/modals/forms/AddCloseTaskModal.vue";
import TaskService from "@/core/services/car/TaskService";

interface DeleteTaskBodyParam {
  task_id: number;
}
interface TaskBodyParam {
  customer_id: number;
  lead_id: number;
  due_date: string;
  disposition_id: number;
  task_notes: string;
  agent: number;
  lost_lead_reason_id: number;
}
export default defineComponent({
  name: "lt-explore",
  components: {
    AddCloseTaskModal,
  },
  props: {
    taskDetails: Array,

    customer: Object,
    leadDetails: Array,
  },
  data() {
    return {
      color: ["success", "primary", "warning", "danger", "info"],
    };
  },
  setup(prop) {
    let isActive = false;

    let deleteTaskBodyParam = {} as DeleteTaskBodyParam;
    const store = useStore();
    const route = useRoute();
    let taskParameters = {} as TaskBodyParam;
    const closeData = ref<any>({})
    onMounted(() => {
      //
    });
    const getLeadTaskList = (lead) => {
      taskParameters.customer_id = lead.customer_id;
      taskParameters.lead_id = lead.id;
      closeData.value = lead
      TaskService.getLeadTaskList(taskParameters);
    };
    const assignDeleteTaskValue = (task_id) => {
      deleteTaskBodyParam.task_id = task_id;
      // console.log(deleteTaskBodyParam);
    };
    const deleteTask = () => {
      TaskService.deleteTask(deleteTaskBodyParam);
    };
    const showAction = () => {
      //
    };
    const showTaskDom = computed(() => {
      return store.getters.getTaskDomStatus;
    });

    const list = computed(() => {
      const data =  prop.leadDetails
      // const quickLead = [2, 10]
      return data;
      // return data?.filter((lead: any) => lead.lead_status_id != 2);.
      // return data?.map((val: any) => ({
      //     ...val,
      //     is_disable: !quickLead.includes(val.lead_status_id) ? false : true
      //   }))
    })

    const nameCount = (a: any, b: any, c: any, d: any) => {
      return a.length + b.length + c.length + d?.length;
    };

    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()
    const taskModal = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const inst = getCurrentInstance()

    const focusToFirst = (data) => {
      const currentLead = prop?.leadDetails?.find((x:any) => x.id == data.lead_id)
      
      closeData.value = currentLead
      const taskModal:any = inst?.refs.taskModal
      taskModal.forEach(x => {
        x.toFirst()
      })
    }

    const toFirst = () => {
      setTimeout(() => {
          const closeTaskRef:any = inst?.refs?.closeTaskRef
          closeTaskRef.forEach(x => {
            x.focus()
          })
      }, 1000);
    }

    function isDisabledButton(lead:any) {
      if(lead.lead_has_pending_invoice) return true

      return false
    }

    const notOmMAs = computed(() => {
      const lsUser = window.localStorage.getItem('user')
      if(lsUser) {
        const user = JSON.parse(lsUser)
        if(![7, 11, 15].includes(user.role_id)) return true
      }

      return false
    })

    return {
      notOmMAs,
      closeData,
      isDisabledButton,
      toFirst,
      focusToFirst,
      taskModal,
      closeRef,
      closeModal,
      list,
      nameCount,
      assignDeleteTaskValue,
      deleteTask,
      showAction,
      getLeadTaskList,
      showTaskDom,
      isActive,
    };
  },
});
